import React from 'react';
import { createIcon } from '@gluestack-ui/icon';
import { Root } from '../styled-components';
import { Path } from 'react-native-svg';
const DownloadIcon = createIcon({
    Root,
    viewBox: '0 0 24 24',
    path: (React.createElement(React.Fragment, null,
        React.createElement(Path, { d: "M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M7 10L12 15L17 10", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M12 15V3", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))),
});
DownloadIcon.displayName = 'DownloadIcon';
export { DownloadIcon };
