import { createIcon } from '@gluestack-ui/icon';
import React from 'react';
import { Path } from 'react-native-svg';
import { Root } from '../styled-components';
const CalendarDaysIcon = createIcon({
    Root,
    viewBox: '0 0 24 24',
    path: (React.createElement(React.Fragment, null,
        React.createElement(Path, { d: "M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M16 2V6", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M8 2V6", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M3 10H21", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M8 14H8.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M12 14H12.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M16 14H16.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M8 18H8.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M12 18H12.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M16 18H16.01", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))),
});
CalendarDaysIcon.displayName = 'CalendarDaysIcon';
export { CalendarDaysIcon };
