import { createIcon } from '@gluestack-ui/icon';
import React from 'react';
import { Path } from 'react-native-svg';
import { Root } from '../styled-components';
const StarIcon = createIcon({
    Root,
    viewBox: '0 0 24 24',
    path: (React.createElement(React.Fragment, null,
        React.createElement(Path, { d: "M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))),
});
StarIcon.displayName = 'StarIcon';
export { StarIcon };
