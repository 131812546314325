// @ts-nocheck
import { styled } from '@gluestack-style/react';
import { StyledIcon } from '../../Icons/styled-components';
export default styled(StyledIcon, {
    ':checked': {
        color: '$backgroundLight0',
    },
    ':disabled': {
        opacity: 0.4,
    },
    '_dark': {
        ':checked': {
            color: '$backgroundDark0',
        },
        ':disabled': {
            opacity: 0.4,
        },
    },
}, {
    componentName: 'CheckboxIcon',
    ancestorStyle: ['_icon'],
}, {
    propertyTokenMap: {
        stroke: 'colors',
    },
});
