import { View } from 'react-native';
import { styled } from '@gluestack-style/react';
export default styled(View, {
    p: '$4',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
}, {
    componentName: 'ModalFooter',
});
