import { createAvatar } from '@gluestack-ui/avatar';
import { Root, Badge, Group, Image, FallbackText } from './styled-components';
export const AccessbileAvatar = createAvatar({
    Root,
    Badge,
    Group,
    Image,
    FallbackText,
});
export const Avatar = AccessbileAvatar;
export const AvatarBadge = AccessbileAvatar.Badge;
export const AvatarGroup = AccessbileAvatar.Group;
export const AvatarImage = AccessbileAvatar.Image;
export const AvatarFallbackText = AccessbileAvatar.FallbackText;
