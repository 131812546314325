import { Motion } from '@legendapp/motion';
import { styled } from '@gluestack-style/react';
import { AnimationResolver } from '@gluestack-style/animation-plugin';
export default styled(Motion.View, {
    alignItems: 'center',
    borderTopLeftRadius: '$3xl',
    borderTopRightRadius: '$3xl',
    h: '$full',
    p: '$2',
    bg: '$backgroundLight0',
    _sectionHeaderBackground: {
        bg: '$backgroundLight0',
    },
    // @ts-ignore
    _dark: {
        bg: '$backgroundDark900',
        _sectionHeaderBackground: {
            bg: '$backgroundDark900',
        },
    },
    // @ts-ignore
    _web: {
        userSelect: 'none',
    },
    defaultProps: {
        hardShadow: '5',
    },
}, {
    componentName: 'ActionsheetContent',
    descendantStyle: ['_sectionHeaderBackground'],
}, {
    plugins: [new AnimationResolver({})],
});
