import { createButton } from '@gluestack-ui/button';
import { Root, Text, Group, GroupHSpacer, GroupVSpacer, Spinner, Icon, } from './styled-components';
const AccessbileButton = createButton({
    Root,
    Text,
    Group,
    GroupHSpacer,
    GroupVSpacer,
    Spinner,
    Icon,
});
export const Button = AccessbileButton;
export const ButtonText = AccessbileButton.Text;
export const ButtonGroup = AccessbileButton.Group;
export const ButtonSpinner = AccessbileButton.Spinner;
export const ButtonIcon = AccessbileButton.Icon;
