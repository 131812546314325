import { createIcon } from '@gluestack-ui/icon';
import React from 'react';
import { Path } from 'react-native-svg';
import { Root } from '../styled-components';
const TrashIcon = createIcon({
    Root,
    viewBox: '0 0 24 24',
    path: (React.createElement(React.Fragment, null,
        React.createElement(Path, { d: "M3 6H21", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M19 6V20C19 21 18 22 17 22H7C6 22 5 21 5 20V6", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement(Path, { d: "M8 6V4C8 3 9 2 10 2H14C15 2 16 3 16 4V6", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))),
});
TrashIcon.displayName = 'TrashIcon';
export { TrashIcon };
